import React from "react";
import { BsDownload, BsGithub, BsLinkedin } from "react-icons/bs";

const Banner = () => {
  return (
    <>
      <div className="hero-box">
        <div className="left-side">
          <h4>Hi there ! 🌺</h4>
          <h2 className="title_banner">I'm Marie-Camille Petit</h2>
          <p>
          A young web developer passionate about front-end and creating web applications.
          </p>
          <div className="hero_box_link">
            <a href="https://firebasestorage.googleapis.com/v0/b/mcp-projet-2023.appspot.com/o/CV_PETIT_MC.pdf.pdf?alt=media&token=a404e728-2d12-4fd6-ba3d-6de4f5846e01">
              Resume
              <span><BsDownload /></span>
            </a>
            <a href="https://github.com/MarieCamillePetit">GitHub<span><BsGithub /></span></a>
            <a href="https://www.linkedin.com/in/mariecamillepetit/">
              Linkedin
              <span><BsLinkedin /></span>
            </a>
          </div>
        </div>
        <div className="right-side">
          <img src="./minimoi.png" className="img_banner" alt="img"></img>
        </div>
      </div>
      <div className="hero-scroll">
        <a href="#about">
          <svg
            width="201px"
            height="201px"
            viewBox="0 0 1024 1024"
            className="icon svg_banner"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="var(--main-pink-color)"
            transform="rotate(0)"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
                fill="var(--main-pink-color)"
              ></path>
            </g>
          </svg>
        </a>
      </div>
    </>
  );
};

export default Banner;
